import React from "react";
import { NextPage } from "next";

const Home: NextPage = () => {
  return (
    <p className="px-3">
      Willkommen im neuen Winkelstück-Reparatur.de Portal.
      <br />
      Von hier aus können neue Reparaturen viel einfacher erstellt werden und alte Reparaturen und Rechnungen
      nachgeschlagen werden
      <br />
      etc etc etc hier kann
    </p>
  );
};

export default Home;
